import * as style from './styles';
import spinnerGif from '../../assets/Spinner-0.9s-201px.gif';

export default function Spinner(props) {
  return (
    <style.SpinnerContainer>
      <style.SpinnerImage src={spinnerGif} />
    </style.SpinnerContainer>
  );
}
