import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`;

export const Login = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 320px;
  height: 300px;
  
  box-shadow: 0px 0px 5px 0.5px lightgray;
  border-radius: 12px;
  
  > img {
    width: 120px;
    
    /* 드래그 방지 */
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
  
  > span {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 11.5px;
    color: gray;
  }
  
  > input {
    all: unset;
    width: 260px;
    height: 40px;
    border: 1px solid lightgray;
    border-radius: 4px;
    margin-top: 8px;
    padding: 4px 8px;
    font-size: 14px;
    box-sizing: border-box;
  }
  
  > button {
    all: unset;
    text-align: center;
    width: 260px;
    height: 40px;
    border-radius: 4px;
    background: #36B4E5;
    color: white;
    font-weight: bold;
    margin-top: 18px;
    cursor: pointer;
  }
`;
