import styled from 'styled-components';

const StyledTable = styled.table`
  border: none;
  border-collapse: collapse;
  table-layout: fixed;
  text-align: left;
  > * > tr > * {
    padding: 15px 15px;
  }
  
  ${(props) => props?.hover && `> tbody > tr {
      cursor: pointer;
      :hover {
      background: lightgray;
      }
    }`}
  
  > thead {
    background: rgb(247, 249, 251);
    > tr > * {
      padding: 20px 15px;
    }
  }
  
  > tbody {
    font-size: 14px;
  }
  
  ${(props) => props?.longTable && `> tbody > tr > td:nth-child(${props.nthChild}) {
    margin: 15px 15px;
    padding: 0;
    width: ${props.maxWidth};
    min-width: ${props.minWidth};
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical
  }`}
`;

export default StyledTable;
