import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Select from 'react-select';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import Spinner from '../../components/Spinner';
import * as style from './styles';
import StyledTable from '../../components/Table';
import { autoFitColumns, formatNumber, toDateFormatString } from '../../utils';

export default function AdminCharge() {
  const navigate = useNavigate();
  const today = new Date();
  const searchOptions = [
    { value: 'id', label: '사업자등록번호' },
    { value: 'name', label: '입금자명' },
    { value: 'hospitalName', label: '병원명' },
  ];
  const [searchQuery, setSearchQuery] = useState({
    type: 'id',
    data: '',
  });
  const [loading, setLoading] = useState(false);
  const [currMenu, setCurrMenu] = useState('accept-list');
  const [token, setToken] = useState();
  const [data, setData] = useState();
  const [currDate, setCurrDate] = useState({
    year: today.getFullYear(),
    month: today.getMonth() + 1,
  });

  const yearOption = [];
  const monthOption = [];

  for (let i = 1; i <= 12; i += 1) {
    monthOption.push({ value: i, label: `${i}월` });
  }

  for (let i = new Date().getFullYear(); i >= new Date().getFullYear() - 20; i -= 1) {
    yearOption.push({ value: i, label: `${i}년` });
  }

  const acceptURL = `${process.env.REACT_APP_API_URI}api/points/accept-list`;
  const pointURL = `${process.env.REACT_APP_API_URI}api/points/list-all?year=${currDate.year}&month=${currDate.month}`;

  const acceptPoint = (point, isAccepted) => {
    if (isAccepted) {
      if (window.confirm(`${point.hospitalName}에 ${point.price.toLocaleString()} 포인트를 충전합니다`)) {
        setLoading(true);
        axios.post(`${process.env.REACT_APP_API_URI}api/points/${point._id}`, {}, {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }).then((res) => {
          alert('충전 완료하였습니다.');
          setLoading(false);
          navigate('/admin-charge');
          window.location.reload();
        }).catch((err) => {
          alert('충전에 실패하였습니다. 다시 시도해주세요.');
        });
      }
    } else if (window.confirm(`${point.hospitalName}에 ${point.price.toLocaleString()} 포인트 신청 건을 삭제합니다`)) {
      setLoading(true);
      axios.delete(`${process.env.REACT_APP_API_URI}api/points/${point._id}`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }).then((res) => {
        alert('삭제 완료하였습니다.');
        setLoading(false);
        navigate('/admin-charge');
        window.location.reload();
      }).catch((err) => {
        alert('삭제에 실패하였습니다. 다시 시도해주세요.');
      });
    }
  };

  const handleMenu = (e) => {
    setCurrMenu(e.target.name);
  };

  const handleSearch = () => {
    if (!token) return;
    setLoading(true);
    setData(null);
    axios.get(`${pointURL}&${searchQuery.type}=${searchQuery.data}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      setData(res.data);
      setLoading(false);
    }).catch((err) => {
      if (err.response?.status === 403) {
        alert('잘못된 접근입니다.');
        navigate('/');
      } else {
        if (err.response?.status === 404) {
          alert('검색조건을 확인 후 다시 검색하세요.');
        }
        console.log(err);
        setLoading(false);
      }
    });
  };

  const handleExcelDownload = async () => {
    const excelFileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const excelFileExtension = '.xlsx';
    const excelFileName = `${currDate.year}년 ${currDate.month}월 포인트 지급목록`;

    const ws = XLSX.utils.aoa_to_sheet([
      ['no.', '충전일시', '병원명', '사업자등록번호', '상호', '대표자명', '주소', '업종', '업태', '세금계산서 email', '입금자명', '신청금액', '충전금액'],
    ]);
    if (!data.points.length) {
      alert('다운로드 할 내용이 없습니다.');
      return;
    }
    data.points.map((point, index) => {
      XLSX.utils.sheet_add_aoa(
        ws,
        [
          [
            index + 1,
            toDateFormatString(new Date(point.acceptedAt || 0)),
            point.hospitalName || point.user.hospitalName,
            point.companyId || point.user.companyId,
            point.user.companyName,
            point.user.representName,
            point.user.address,
            point.user.companyType,
            point.user.companyItem,
            point.email || point.user.email,
            point.name === '문자발송기' ? '관리자' : point.name,
            point.name === '문자발송기' ? '' : point.price.toLocaleString(),
            point.price.toLocaleString(),
          ],
        ],
        { origin: -1 },
      );
      return false;
    });
    // autoFitColumns(ws);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelButter = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const excelFile = new Blob([excelButter], { type: excelFileType });
    FileSaver.saveAs(excelFile, excelFileName + excelFileExtension);
    setLoading(false);
  };

  useEffect(() => {
    const menuURL = window.location.search && window.location.search.split('=')[1] === 'point-list'
      ? pointURL
      : acceptURL;
    if (window.location.search) setCurrMenu(window.location.search.split('=')[1]);
    setLoading(true);
    const userToken = sessionStorage.getItem('token');
    if (!userToken) {
      alert('잘못된 접근입니다.');
      navigate('/');
    } else {
      setToken(userToken);
      axios.get(menuURL, {
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      }).then((res) => {
        setData(res.data);
        setLoading(false);
      }).catch((err) => {
        if (err.response?.status === 403) {
          alert('잘못된 접근입니다.');
          navigate('/');
        } else {
          console.log(err);
          setLoading(false);
        }
      });
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    setData(null);
    if (currMenu === 'point-list') {
      const userToken = sessionStorage.getItem('token');
      if (!userToken) {
        alert('잘못된 접근입니다.');
        navigate('/');
      } else {
        axios.get(pointURL, {
          headers: {
            authorization: `Bearer ${userToken}`,
          },
        }).then((res) => {
          setData(res.data);
          setLoading(false);
        }).catch((err) => {
          if (err.response?.status === 403) {
            alert('잘못된 접근입니다.');
            navigate('/');
          } else {
            console.log(err);
            setLoading(false);
          }
        });
      }
    } else if (currMenu === 'accept-list') {
      const userToken = sessionStorage.getItem('token');
      if (!userToken) {
        alert('잘못된 접근입니다.');
        navigate('/');
      } else {
        axios.get(acceptURL, {
          headers: {
            authorization: `Bearer ${userToken}`,
          },
        }).then((res) => {
          setData(res.data);
          setLoading(false);
        }).catch((err) => {
          if (err.response?.status === 403) {
            alert('잘못된 접근입니다.');
            navigate('/');
          } else {
            console.log(err);
            setLoading(false);
          }
        });
      }
    }
  }, [currMenu, currDate]);

  useEffect(() => {
    setCurrDate({
      year: today.getFullYear(),
      month: today.getMonth() + 1,
    });
  }, [currMenu]);

  useEffect(() => {
    setSearchQuery({ ...searchQuery, data: '' });
    setLoading(true);
    const userToken = sessionStorage.getItem('token');
    if (!userToken) {
      alert('잘못된 접근입니다.');
      navigate('/');
    } else {
      axios.get(pointURL, {
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      }).then((res) => {
        setData(res.data);
        setLoading(false);
      }).catch((err) => {
        if (err.response?.status === 403) {
          alert('잘못된 접근입니다.');
          navigate('/');
        } else {
          console.log(err);
          setLoading(false);
        }
      });
    }
  }, [currDate]);

  return (
    <>
      { loading && <Spinner /> }
      <style.Header>
        <button type="button" name="user" onClick={() => navigate('/admin?menu=user')}>고객 목록</button>
        <button type="button" name="accept-list" onClick={handleMenu}>포인트 신청</button>
        <button type="button" name="point-list" onClick={handleMenu}>포인트 지급</button>
        <button type="button" name="settlement" onClick={() => navigate('/admin?menu=settlement')}>정산 목록</button>
      </style.Header>
      <style.MainContainer>
        <style.ContentContainer>
          { currMenu === 'accept-list' && (
          <style.Content flexDirection="column">
            <h2>포인트 신청목록</h2>
            <StyledTable>
              <thead>
                <tr>
                  <th>no.</th>
                  <th>병원명</th>
                  <th>대표자명</th>
                  <th>주소</th>
                  <th>사업자등록번호(ID)</th>
                  <th>신청일시</th>
                  <th>입금자명</th>
                  <th>신청금액</th>
                  <th>충전</th>
                  <th>삭제</th>
                </tr>
              </thead>
              <tbody>
                {
                !loading && data && data.points.map((point, index) => (
                  <tr key={point._id} id={point._id}>
                    <td>{index + 1}</td>
                    <td>{point.hospitalName}</td>
                    <td>{point.user.representName}</td>
                    <td>{point.user.address}</td>
                    <td>{point.companyId}</td>
                    <td>{toDateFormatString(new Date(point.createdAt))}</td>
                    <td>{point.name}</td>
                    <td>{point.price.toLocaleString()}</td>
                    <td><style.Button active type="button" onClick={() => acceptPoint(point, true)}>충전</style.Button> </td>
                    <td><style.Button type="button" onClick={() => acceptPoint(point, false)}>삭제</style.Button> </td>
                  </tr>
                ))
              }
              </tbody>
            </StyledTable>
          </style.Content>
          )}
          { currMenu === 'point-list' && (
            <style.Content flexDirection="column">
              <h2>포인트 지급목록</h2>
              <style.Search>
                <Select
                  options={yearOption}
                  defaultValue={yearOption[0]}
                  onChange={(newValue) => {
                    setCurrDate({ ...currDate, year: newValue.value });
                  }}
                />
                <Select
                  options={monthOption}
                  defaultValue={monthOption[today.getMonth()]}
                  onChange={(newValue) => {
                    setCurrDate({ ...currDate, month: newValue.value });
                  }}
                />
                <Select
                  options={searchOptions}
                  defaultValue={searchOptions[0]}
                  onChange={(newValue) => {
                    setSearchQuery({ ...searchQuery, type: newValue.value });
                  }}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      width: '180px',
                    }),
                  }}
                />
                <input
                  type="text"
                  onChange={(e) => {
                    setSearchQuery({ ...searchQuery, data: e.target.value });
                  }}
                />
                <button type="button" onClick={handleSearch}>Search</button>
              </style.Search>
              <button type="button" onClick={handleExcelDownload}>다운로드</button>
              <StyledTable>
                <thead>
                  <tr>
                    <th>no.</th>
                    <th>병원명</th>
                    <th>대표자명</th>
                    <th>주소</th>
                    <th>사업자등록번호(ID)</th>
                    <th>충전일시</th>
                    <th>입금자명</th>
                    <th>신청금액</th>
                    <th>충전금액</th>
                  </tr>
                </thead>
                <tbody>
                  {
                  data && data.points.map((point, index) => (
                    <tr key={point._id} id={point._id}>
                      <td>{index + 1}</td>
                      <td>{point.hospitalName || point.user.hospitalName}</td>
                      <td>{point.user.representName}</td>
                      <td>{point.user.address}</td>
                      <td>{point.companyId || point.user.companyId}</td>
                      <td>{toDateFormatString(new Date(point.acceptedAt || 0))}</td>
                      <td>{point.name}</td>
                      <td>{point.name === '문자발송기' ? '-' : point.price.toLocaleString()}</td>
                      <td style={{ ...((point.price < 0) && { color: '#FF4E27' }) }}>{point.price.toLocaleString()}</td>
                    </tr>
                  ))
                }
                </tbody>
              </StyledTable>
            </style.Content>
          )}
        </style.ContentContainer>
      </style.MainContainer>
    </>
  );
}
