import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  width: 100vw;
  min-width: 1250px;
  height: 100vh;
  z-index: 10;
  background: rgb(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  background: #ffffff;
  border-radius: 8px;
  width: 440px;
  padding: 30px 40px;
  box-sizing: border-box;

  > h2 {
    text-align: center;
    margin: 10px 0;
    font-size: 20px;
  }

  > h3 {
    margin: 10px 0;
    font-size: 20px;
  }

  > h4 {
    padding-bottom: 10px;
    font-size: 18px;
    border-bottom: 1px solid black;
  }

  > h5 {
    text-align: center;
    font-size: 100%;
    margin: 10px 0;
  }

  > span {
    display: flex;
    width: 100%;
    justify-content: center;
    font-size: 13px;
    color: #a6a6a6;
  }

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 34px;
    
    > img {
      width: 36px;
      cursor: pointer;
    }

    > div {
      display: flex;
      align-items: center;
    }
  }

  > b {
    display: block;
  }

  > input {
    width: 100%;
    padding: 10px 8px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 20px;
    border: 1px solid #dcdfe2;
    border-radius: 4px;
    
    &:disabled {
      background: #dcdfe2;
      color: gray;
    }
  }
`;

export const Button = styled.button`
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  border-radius: 8px;
  font-size: 15px;
  color: #ffffff;
  width: 100%;
  height: 48px;
  border: none;
  background: ${(props) => (props.active ? '#36aede' : '#c5c9cd')};
  ${(props) => (props.active && 'cursor: pointer;')}
`;

export const CopyButton = styled.div`
  border: 1px solid #36B4E5;
  margin-left: 6px;
  padding: 4px 6px;
  font-size: 14px;
  cursor: pointer;
  font-weight: bold;
  border-radius: 4px;
  color: #36B4E5;
`;
