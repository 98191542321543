import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  min-width: 1250px;
  padding-top: 48px;
  height: 48px;
  justify-content: center;
  align-items: center;
  background: #ffffff;
`;

export const Link = styled.a`
  all: unset;
  cursor: pointer;
  font-weight: bold;
  display: flex;
  box-sizing: border-box;
  padding: 12px 20px;
  border-bottom: ${(props) => (props.active ? '3px solid #36B4E5' : '3px solid #fff')};
  
  :hover {
    border-bottom: 3px solid #36B4E5;
  }
`;
