import styled from 'styled-components';

export const Pages = styled.div`
  height: 40px;
  display: flex;
  align-self: center;
  gap: 20px;
  margin-top: 20px;
`;

export const Page = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 9999px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid black;
  box-sizing: border-box;
  font-weight: bold;
  cursor: pointer;
  ${(props) => props?.active && 'background: black; color: white; cursor: unset;'}
`;

export const Skip = styled.img`
  width: 40px;
  height: 40px;
  cursor: pointer;
  ${(props) => props?.name === 'next' && 'transform: rotate(180deg);'}
  ${(props) => props?.disabled && 'filter: opacity(25%); cursor: unset;'}
  /* 드래그 방지 */
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
`;
