import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-width: 1250px;
  height: 100vh;
  overflow: auto;
  background: rgb(247, 249, 251);
  padding-bottom: 300px;
  
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 1200px;
  padding-bottom: 200px;
  align-items: center;
  gap: 20px;

  > h2 {
    color: #36B4E5;
  }

  > h3 {
    margin: 0;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 1250px;
  height: 48px;
  background: #36B4E5;
  position: absolute;
  z-index: 2;
  font-weight: bold;
  color: #ffffff;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background: #ffffff;
  width: 440px;
  padding: 30px;
  box-sizing: border-box;
  border: 1px solid lightgrey;
  
  &:nth-child(7) {
    display: flex;
    flex-direction: column;
    > div {
      margin-bottom: 10px;
      font-weight: bold;
      > label {
        margin-left: 10px;
      }
    }
  }
  
  > h3 {
    height: 40px;
    display: flex;
    align-items: center;
    margin: 0 0 20px 0;
    border-bottom: 1px solid black;
    > span {
      font-weight: normal;
    }
    > div {
      display: flex;
      align-items: center;
      margin-left: auto;
      > span {
        font-weight: normal;
        font-size: 12px;
        margin-right: 10px;
      }
      > h3 {
        margin: 0;
        color: #36B4E5;
      }
    }
  }
`;

export const SpaceDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  
  > span {
    font-size: 16px;
  }
  
  > span {
    font-weight: bold;
    font-size: 17px;
    &.normal {
      font-weight: normal;
      font-size: 14px;
    }
  }
  
  > div > input {
    all: unset;
    font-family: "Noto Sans KR";
    font-weight: normal;
    width: 140px;
    border: 1px solid lightgrey;
    border-radius: 4px;
    height: 30px;
    margin-right: 8px;
    text-align: right;
    padding: 0 8px;
  }
  
  > div > input::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  > div > input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

export const Button = styled.button`
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  cursor: pointer;
  background: ${(props) => (props.background ? props.background : '#36B4E5')};
  width: 300px;
  border-radius: 8px;
  color: #ffffff;
  height: 48px;
`;
