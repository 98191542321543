import styled from 'styled-components';

export const FormContainer = styled.div`
  width: 100%;
  height: 100%;
  min-width: 1250px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  background: rgb(255, 255, 255, 0.9);
`;

export const Form = styled.div`
  width: 800px;
  height: auto;
  display: flex;
  top: 0;
  background: white;
  border-radius: 16px;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0px 0px 16px 0.5px lightgray;

  > h2 {
    font-size: 18px;
    margin: 10px 0 20px 0;
  }
`;

export const SideFormWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  margin-top: 16px;

  > div {
    width: 48%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
`;

export const Input = styled.div`
  display: flex;
  flex-direction: ${(props) => (props?.row ? 'row' : 'column')};
  width: 100%;
  justify-content: space-between;

  > span {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 4px;
  }

  > input {
    all: unset;
    background: white;
    padding: 6px 10px;
    box-sizing: border-box;
    border: 1px solid lightgray;
    ${(props) => (props?.row && 'width: 48%')}
  }

  > button {
    all: unset;
    width: 48%;
    height: 38px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-sizing: border-box;
    font-weight: 500;
      
    :nth-child(1) {
      border: 1px solid rgb(94, 178, 221);
    }

    :nth-child(2) {
      background: #36B4E5;
      color: white;
    }
  }
`;
