import { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import * as style from './styles';
import { doCopy } from '../../utils';
import xIcon from '../../assets/x_icon.png';

export default function ChargeModal(props) {
  const {
    price = 0, companyId = '', email = '', token, setLoading,
  } = props;
  const navigate = useNavigate();
  const [isFinal, setIsFinal] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [formData, setFormData] = useState({
    hospitalName: sessionStorage.getItem('hospitalName'),
    companyId,
    email,
    price,
    name: '',
  });

  useEffect(() => {
    const { setModal, ...userData } = props;
    setFormData({ ...formData, ...userData });
  }, [props]);

  useEffect(() => {
    if (Object.values(formData).map((data) => Boolean(data)).includes(false)) {
      setIsComplete(false);
    } else setIsComplete(true);
  }, [formData]);

  const handleFormData = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const submitForm = () => {
    if (isComplete) {
      if (!token) {
        alert('잘못된 접근입니다.');
        navigate('/');
      }
      setLoading(true);
      axios.post(`${process.env.REACT_APP_API_URI}api/points`, formData, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }).then((res) => {
        setLoading(false);
        setIsFinal(true);
      }).catch((err) => {
        alert('오류가 발생했습니다. 다시 시도해주세요.');
        setLoading(false);
      });
    }
  };

  return (
    <style.Container>
      <style.Content>
        {isFinal ? (
          <>
            <h2>문자 포인트 신청 완료</h2>
            <h5>입금 확인 후 약 15분 이내 포인트가 충전됩니다.</h5>
            <span>영업일 기준, 영업일, 월~금, 오전 10시부터 오후 6시까지</span>
            {/* eslint-disable-next-line react/destructuring-assignment */}
            <style.Button active onClick={() => props.setModal(false)}>
              확인
            </style.Button>
          </>
        ) : (
          <>
            <div>
              <h3>계좌이체 정보 입력</h3>
              <img src={xIcon} onClick={() => props.setModal(false)} />
            </div>
            <h4>받는 곳</h4>
            <div>
              <b>예금주명</b>
              <span>문자연구소 주식회사</span>
            </div>
            <div>
              <b>은행</b>
              <span>우리은행</span>
            </div>
            <div>
              <b>계좌번호</b>
              <div>
                <span>1005-404-365825</span>
                <style.CopyButton onClick={() => doCopy('1005404365825')}>복사</style.CopyButton>
              </div>
            </div>
            <div>
              <b>금액</b>
              <span>{price.toLocaleString()}원</span>
            </div>
            <h4>보내는 곳</h4>
            <b>입금자명(필수)</b>
            <input type="text" name="name" onChange={handleFormData} />
            <b>한의원명(필수)</b>
            <input type="text" name="hospitalName" defaultValue={sessionStorage.getItem('hospitalName')} onChange={handleFormData} />
            <b>사업자등록번호(필수)</b>
            <input type="text" name="companyId" defaultValue={companyId.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3')} onChange={handleFormData} />
            <b>전자세계산서 수신 메일(필수)</b>
            <input type="text" name="email" defaultValue={email} onChange={handleFormData} />
            <span>입금 확인 1영업일 이후 세금계산서가 자동 발행됩니다.</span>
            <style.Button active={isComplete} onClick={submitForm}>완료</style.Button>
          </>
        )}
      </style.Content>
    </style.Container>
  );
}
