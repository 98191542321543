import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as style from './styles';
import Spinner from '../../components/Spinner';
import { PrepaidMenu } from '../../components/PrepaidMenu';
import monthMove from '../../assets/month_move.svg';
import StyledTable from '../../components/Table';
import Pages from '../../components/Pages';
import { toDateFormatString } from '../../utils';

export default function UserChargeList() {
  const navigate = useNavigate();
  const today = new Date();
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState();
  const [hospitalName, setHospitalName] = useState();
  const [data, setData] = useState();
  const [currPage, setCurrPage] = useState(1);
  const [currDate, setCurrDate] = useState({
    year: today.getFullYear(),
    month: today.getMonth() + 1,
    disabled: true,
  });

  const dataURL = `${process.env.REACT_APP_API_URI}api/points/list?page=${currPage}&year=${currDate.year}&month=${currDate.month}`;

  const getData = () => {
    if (!token) return;
    setLoading(true);
    setData(null);
    axios.get(dataURL, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      setData(res.data);
      setLoading(false);
    }).catch((err) => {
      if (currDate.month === today.getMonth() + 1);
      else if (err.response?.status === 404) alert('정보가 없습니다.');
      setLoading(false);
    });
  };

  const handleDate = (e) => {
    if (e.target.name === 'prev') {
      if (currDate.month <= 1) {
        setCurrDate({
          year: currDate.year - 1,
          month: 12,
          disabled: false,
        });
      } else {
        setCurrDate({
          ...currDate,
          month: currDate.month - 1,
          disabled: false,
        });
      }
    } else if (e.target.name === 'next' && !currDate.disabled) {
      if (currDate.month >= 12) {
        setCurrDate({
          year: currDate.year + 1,
          month: 1,
          disabled: today.getFullYear() === currDate.year + 1 && today.getMonth() + 1 === 1,
        });
      } else {
        setCurrDate({
          ...currDate,
          month: currDate.month + 1,
          disabled:
            today.getFullYear() === currDate.year && today.getMonth() + 1 === currDate.month + 1,
        });
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    const userToken = sessionStorage.getItem('token');
    const name = sessionStorage.getItem('hospitalName');
    if (!userToken) {
      alert('잘못된 접근입니다.');
      navigate('/');
    } else {
      setData(null);
      setToken(userToken);
      setHospitalName(name);
      axios.get(dataURL, {
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      }).then((res) => {
        setData(res.data);
        setLoading(false);
      }).catch((err) => {
        const { message, ...userInfo } = err.response.data;
        if (currDate.month === today.getMonth() + 1);
        else if (err.response?.status === 404) alert('정보가 없습니다.');
        setLoading(false);
      });
    }
  }, []);

  useEffect(() => {
    setCurrPage(1);
    getData();
  }, [currDate]);

  useEffect(() => {
    getData();
  }, [currPage]);

  return (
    <>
      {loading && <Spinner />}
      <style.Header>
        {hospitalName}
      </style.Header>
      <PrepaidMenu />
      <style.MainContainer>
        <style.ContentContainer>
          <style.Month>
            <style.PosButton src={monthMove} name="prev" onClick={handleDate} />
            <span>
              {currDate.year}년 {currDate.month}월
            </span>
            <style.PosButton src={monthMove} name="next" onClick={handleDate} disabled={currDate.disabled} />
          </style.Month>
          <style.Content flexDirection="column">
            <h2>문자 포인트 충전</h2>
            <StyledTable longTable>
              <thead>
                <tr>
                  <th>no.</th>
                  <th>신청일시</th>
                  <th>입금자명</th>
                  <th>신청금액</th>
                  <th>지급일시</th>
                  <th>충전금액</th>
                </tr>
              </thead>
              <tbody>
                {
                !loading && data && data.pointList.map((point, index) => (
                  <tr key={point._id}>
                    <td>
                      {(currPage - 1) * 15 + index + 1}
                    </td>
                    <td>
                      {toDateFormatString(new Date(point.createdAt))}
                    </td>
                    <td>
                      {point.name}
                    </td>
                    <td>
                      {point.name === '문자발송기' ? '-' : point.price.toLocaleString()}
                    </td>
                    <td>
                      {point.acceptedAt ? toDateFormatString(new Date(point.acceptedAt)) : '-'}
                    </td>
                    <td style={(point.price < 0 ? { color: 'red' } : {})}>
                      {point.price.toLocaleString()}
                    </td>
                  </tr>
                ))
              }
              </tbody>
            </StyledTable>
            {data
              && <Pages totalPage={data.totalPage} currPage={currPage} setCurrPage={setCurrPage} />}
          </style.Content>
        </style.ContentContainer>
      </style.MainContainer>
    </>
  );
}
