import Select from 'react-select';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import * as style from './styles';
import monthMove from '../../assets/month_move.svg';
import StyledTable from '../../components/Table';
import Spinner from '../../components/Spinner';
import Pages from '../../components/Pages';
import {
  getErrorList,
  doCopy,
  formatNumber,
  toDateFormatString,
} from '../../utils';
import { PrepaidMenu } from '../../components/PrepaidMenu';

export default function User(props) {
  const navigate = useNavigate();
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState('');
  const [data, setData] = useState();
  const [userData, setUserData] = useState();
  const [currPage, setCurrPage] = useState(1);
  const [hospitalName, setHospitalName] = useState('병원명');
  const [currDate, setCurrDate] = useState({
    year: today.getFullYear(),
    month: today.getMonth() + 1,
    disabled: true,
  });
  const [newPassword, setNewPassword] = useState({
    password: '',
    check: '',
    isValid: false,
  });
  const [queryStartDate, setQueryStartDate] = useState(
    new Date(today.getFullYear(), today.getMonth() - 1, today.getDate()).getTime(),
  );
  const [queryEndDate, setQueryEndDate] = useState(today.getTime());
  const searchOptions = [
    { value: 'phone', label: '수신번호' },
    { value: 'text', label: '메시지' },
  ];
  const [searchQuery, setSearchQuery] = useState({
    start: new Date(today.getFullYear() - 1, today.getMonth(), today.getDate()).getTime(),
    end: today.getTime(),
    type: 'phone',
    data: '',
  });
  const [isSearch, setIsSearch] = useState(false);
  const [isPrepaid, setIsPrepaid] = useState(false);
  const [errorList, setErrorList] = useState({});

  const dataURL = `${process.env.REACT_APP_API_URI}api/messages?page=${currPage}&year=${currDate.year}&month=${currDate.month}`;
  const searchURL = `${process.env.REACT_APP_API_URI}api/messages?page=${currPage}&start=${searchQuery.start}&end=${searchQuery.end}&${searchQuery.type}=${searchQuery.data}`;

  const handleDate = (e) => {
    if (e.target.name === 'prev') {
      if (currDate.month <= 1) {
        setCurrDate({
          year: currDate.year - 1,
          month: 12,
          disabled: false,
        });
      } else {
        setCurrDate({
          ...currDate,
          month: currDate.month - 1,
          disabled: false,
        });
      }
    } else if (e.target.name === 'next' && !currDate.disabled) {
      if (currDate.month >= 12) {
        setCurrDate({
          year: currDate.year + 1,
          month: 1,
          disabled: today.getFullYear() === currDate.year + 1 && today.getMonth() + 1 === 1,
        });
      } else {
        setCurrDate({
          ...currDate,
          month: currDate.month + 1,
          disabled:
            today.getFullYear() === currDate.year && today.getMonth() + 1 === currDate.month + 1,
        });
      }
    }
  };

  const handleInputPassword = (e) => {
    if (e.target.name === 'check') {
      setNewPassword({
        ...newPassword, check: e.target.value,
      });
    } else {
      setNewPassword({
        ...newPassword,
        password: e.target.value,
        isValid: /^(?=.*\d)(?=.*[a-z])[\da-zA-Z!@#-]{8,}$/.test(e.target.value),
      });
    }
  };

  const handleChangePassword = () => {
    if (newPassword?.password && newPassword?.check) {
      const { password, check, isValid } = newPassword;
      if (password !== check) {
        alert('비밀번호가 일치하지 않습니다.');
      } else if (!isValid) {
        alert('비밀번호는 영문과 숫자를 포함한 8자로 작성해주세요.');
      } else {
        setLoading(true);
        axios.post(`${process.env.REACT_APP_API_URI}api/users/my`, { password }, {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }).then((res) => {
          alert('변경이 완료되었습니다.');
          setLoading(false);
          setModal(false);
        }).catch((err) => {
          console.log(err);
          alert('문제가 발생했습니다. 다시 시도해주세요.');
          setLoading(false);
        });
      }
    } else alert('비밀번호를 입력해주세요.');
  };

  const getData = () => {
    if (!token) return;
    setLoading(true);
    setData(null);
    axios.get(isSearch ? searchURL : dataURL, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      setData(res.data);
      setLoading(false);
    }).catch((err) => {
      if (currDate.month === today.getMonth() + 1);
      else if (err.response?.status === 404) alert('정보가 없습니다.');
      setLoading(false);
    });
  };

  const handleQueryDate = (date, type) => {
    const time = date.getTime();
    if (type === 'start') {
      if (time > searchQuery.end) setQueryEndDate(null);
      setSearchQuery({ ...searchQuery, start: time });
      setQueryStartDate(date);
    } else if (type === 'end') {
      if (time < searchQuery.start) setQueryStartDate(null);
      setSearchQuery({ ...searchQuery, end: time });
      setQueryEndDate(date);
    }
  };

  const handleSearch = () => {
    if (Object.values(searchQuery).map((value) => Boolean(value)).includes(false)) {
      alert('검색 조건을 모두 채워주세요.');
      return;
    }
    if (!token) {
      alert('잘못된 접근입니다.');
      navigate('/');
    }
    setCurrPage(1);
    setIsSearch(true);
    setLoading(true);
    setData(null);
    axios.get(searchURL, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      setData(res.data);
      setLoading(false);
    }).catch((err) => {
      if (err.response?.status === 404) alert('검색 조건을 확인해주세요.');
      setLoading(false);
    });
  };

  useEffect(() => {
    setLoading(true);
    const userToken = sessionStorage.getItem('token');
    const name = sessionStorage.getItem('hospitalName');
    if (!userToken) {
      alert('잘못된 접근입니다.');
      navigate('/');
    } else {
      setData(null);
      setToken(userToken);
      setHospitalName(name);
      getErrorList().then((list) => {
        setErrorList(list);
      }).catch((err) => console.log(err));
      axios.get(dataURL, {
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      }).then((res) => {
        const {
          companyId, priceSMS, priceMMS, priceLMS, email, role, points,
        } = res.data;
        setData(res.data);
        setUserData({
          companyId, priceSMS, priceMMS, priceLMS, email, role, points,
        });
        setIsPrepaid(role === 2);
        setLoading(false);
      }).catch((err) => {
        const { message, ...userInfo } = err.response.data;
        setUserData(userInfo);
        setIsPrepaid(userInfo.role === 2);
        if (currDate.month === today.getMonth() + 1);
        else if (err.response?.status === 404) alert('정보가 없습니다.');
        setLoading(false);
      });
    }
  }, []);

  useEffect(() => {
    setCurrPage(1);
    getData();
  }, [currDate]);

  useEffect(() => {
    getData();
  }, [currPage]);

  return (
    <>
      {loading && <Spinner />}
      <style.Header>
        {hospitalName}
        {/* <button type="button" onClick={() => setModal(true)}>비밀번호 변경</button> */}
      </style.Header>
      {isPrepaid && <PrepaidMenu />}
      <style.MainContainer>
        <style.ContentContainer isPrepaid={isPrepaid}>
          <style.Query>
            <style.SelectDate>
              <DatePicker
                selected={queryStartDate}
                onChange={(date) => handleQueryDate(date, 'start')}
                dateFormat="yyyy-MM-dd"
                minDate={new Date().setFullYear(today.getFullYear() - 1)}
                maxDate={today}
                id="start"
                placeholderText="시작: YYYY-MM-DD"
              />
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="start" />
            </style.SelectDate>
            <span>~</span>
            <style.SelectDate>
              <DatePicker
                selected={queryEndDate}
                onChange={(date) => handleQueryDate(date, 'end')}
                dateFormat="yyyy-MM-dd"
                minDate={new Date().setFullYear(today.getFullYear() - 1)}
                maxDate={today}
                id="end"
                placeholderText="종료: YYYY-MM-DD"
              />
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="end" />
            </style.SelectDate>
            <Select
              options={searchOptions}
              defaultValue={searchOptions[0]}
              onChange={(newValue) => {
                setSearchQuery({ ...searchQuery, type: newValue.value });
              }}
              styles={{
                control: (provided) => ({
                  ...provided,
                  width: '180px',
                }),
              }}
            />
            <input
              type="text"
              name="query"
              onChange={(e) => setSearchQuery({ ...searchQuery, data: e.target.value })}
            />
            <button
              type="button"
              onClick={handleSearch}
            >검색
            </button>
            {isSearch && (<a href="/user">검색 조건 초기화</a>) }
          </style.Query>
          {!isSearch && (
          <style.Month>
            <style.PosButton src={monthMove} name="prev" onClick={handleDate} />
            <span>
              {currDate.year}년 {currDate.month}월
            </span>
            <style.PosButton src={monthMove} name="next" onClick={handleDate} disabled={currDate.disabled} />
          </style.Month>
          )}
          {isPrepaid && (
          <style.Points>
            잔여포인트 : {userData.points.toLocaleString()} P
          </style.Points>
          )}
          {!isSearch && (
            <style.Content>
              <span>예상 사용내역</span>
              <span>
                {data ? formatNumber(
                  (((data?.sms && data?.priceSMS && data.sms * data.priceSMS) || 0)
                + ((data?.lms && data?.priceLMS && data.lms * data.priceLMS) || 0)
                + ((data?.mms && data?.priceMMS && data.mms * data.priceMMS) || 0)),
                ) : 0}원
              </span>
              <span>
                SMS: { data ? formatNumber(data.sms) : 0 }건,
                LMS: { data ? formatNumber(data.lms) : 0}건,
                MMS: { data ? formatNumber(data.mms) : 0}건
              </span>
              <span>
                *문자발송단가: SMS={ (userData && userData?.priceSMS) || 12 }원,
                LMS={ (userData && userData?.priceLMS) || 36 }원,
                MMS={ (userData && userData?.priceMMS) || 70 }원 (1건당, 부가세포함)
              </span>
            </style.Content>
          )}
          <style.Content flexDirection="column">
            {isSearch ? (<h2><a href="/user">전체</a>&gt;검색결과</h2>) : (<h2>전체</h2>)}
            <StyledTable longTable nthChild="4" minWidth="30rem" maxWidth="48vw">
              <thead>
                <tr>
                  <th>no.</th>
                  <th>발송일시</th>
                  <th>수신번호</th>
                  <th>메시지</th>
                  <th>복사</th>
                  <th>결과</th>
                  <th>발송실패 사유</th>
                </tr>
              </thead>
              <tbody>
                {
              data && data.messages.map((message, index) => (
                <tr key={message._id}>
                  <td>
                    {(currPage - 1) * 15 + index + 1}
                  </td>
                  <td>{
                    message?.RecvTime
                      ? message.RecvTime.replace(
                        /^(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})$/,
                        '$1-$2-$3 $4:$5:$6',
                      )
                      : toDateFormatString(new Date(message.createdAt))
}
                  </td>
                  <td>{message.Phone.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, '$1-$2-$3')}</td>
                  <td>
                    {message.text}
                  </td>
                  <td>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a href="#" onClick={() => doCopy(message.text)}>복사</a>
                  </td>
                  <td>{
                    message.Result
                      ? (message.Result === 2 ? '성공' : '실패')
                      : '전송중'
                  }
                  </td>
                  <td>{(message.Result !== 2 && message?.Error && errorList)
                    ? errorList[message.Error] : '-'}
                  </td>
                </tr>
              ))
            }
              </tbody>
            </StyledTable>
            {data
              && <Pages totalPage={data.totalPage} currPage={currPage} setCurrPage={setCurrPage} />}
          </style.Content>
        </style.ContentContainer>
      </style.MainContainer>
      {modal && (
        <style.ModalContainer>
          <style.Modal>
            <h2>비밀번호 변경</h2>
            <span>
              영문, 숫자 8자 이상 입력하세요. <br />
              (사용가능한 특수문자 !, @, #)
            </span>
            <input type="password" name="password" onChange={handleInputPassword} placeholder="새 비밀번호" maxLength="12" />
            <input type="password" name="check" onChange={handleInputPassword} placeholder="새 비밀번호 확인" maxLength="12" />
            <button type="button" onClick={handleChangePassword}>확인</button>
            <button type="button" onClick={() => setModal(false)}>취소</button>
          </style.Modal>
        </style.ModalContainer>
      )}
    </>
  );
}
