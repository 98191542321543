import styled from 'styled-components';
import Calendar from '../../assets/calendar.png';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-width: 1250px;
  height: 100vh;
  overflow: auto;
  background: rgb(247, 249, 251);
`;
export const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 1250px;
  height: 48px;
  background: #36B4E5;
  font-size: 16px;
  color: white;
  font-weight: bold;
  position: absolute;
  z-index: 2;
  
  > button {
    all: unset; 
    font-size: 14px;
    font-weight: 500;
    position: absolute;
    right: 5%;
    cursor: pointer;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 1200px;
  width: 95%;
  padding-bottom: 200px;
  align-items: center;
  padding-top: ${(props) => (props.isPrepaid ? '0' : '40px')};
`;

export const Month = styled.div`
  width: 230px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > span {
    margin: 0 20px;
    font-weight: bold;
  }
  margin-top: 40px;
`;

export const PosButton = styled.img`
  width: 36px;
  cursor: pointer;

  ${(props) => props?.name === 'next' && 'transform: rotate(180deg);'}
  ${(props) => props?.disabled && 'filter: opacity(25%); cursor: unset;'}
  
  
  /* 드래그 방지 */
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
`;

export const Content = styled.div`
  display: flex;
  ${(props) => props?.flexDirection && `flex-direction: ${props.flexDirection};`}
  width: 100%;
  box-sizing: border-box;
  background: white;
  border-radius: 8px;
  padding: 16px 20px;
  margin-top: 30px;
  border: 1px solid #e3e3e3;
  
  > h2 {
    margin: 20px 0;
  }
  
  > span {
    :nth-child(1) {
      font-weight: bold;
      margin-right: 30px;
    }
    :nth-child(2) {
      font-weight: bold;
      margin-right: 10px;
    }
    :last-child {
      margin-left: auto;
      font-size: 12px;
      color: gray;
    }
  }
  
`;

export const ModalContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  width: 100%;
  min-width: 1250px;
  min-height: calc(100% - 48px);
  background: #4a4c62;
`;

export const Modal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 320px;
  height: 340px;
  background: white;
  border-radius: 12px;
  
  > h2 {
    font-size: 18px;
    margin: 0 0 10px 0;
  }

  > span {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 12px;
    color: gray;
    text-align: center;
  }

  > input {
    all: unset;
    width: 260px;
    height: 40px;
    border: 1px solid lightgray;
    border-radius: 4px;
    padding: 4px 8px;
    font-size: 14px;
    box-sizing: border-box;
    margin-top: 4px;
    
    :nth-child(4) {
      margin-bottom: 12px;
    }
  }

  > button {
    all: unset;
    text-align: center;
    width: 260px;
    height: 40px;
    border-radius: 4px;
    background: #36B4E5;
    color: white;
    font-weight: bold;
    margin-top: 4px;
    cursor: pointer;

    :nth-child(6) {
      background: white;
      border: 1px solid rgb(94, 178, 221);
      color: rgb(94, 178, 221);
    }
  }
`;

export const Query = styled.div`
  display: flex;
  align-items: center;
  align-self: start;
  gap: 10px;
  
  margin-top: 40px;

  > button {
    all: unset;
    display: flex;
    align-self: flex-start;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border-radius: 4px;
    width: 80px;
    height: 40px;
    background: #36B4E5;
    color: #ffffff;
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
  }

  > input {
    all: unset;
    width: 260px;
    height: 40px;
    border: 1px solid lightgray;
    border-radius: 4px;
    padding: 4px 8px;
    font-size: 14px;
    box-sizing: border-box;
    background: #ffffff;
  }
  
  > a {
    all: unset;
    color: #c3c3c3;
    font-size: 14px;
    margin-left: 20px;
    cursor: pointer;
    &:hover {
      color: black;
    }
  }
`;

export const SelectDate = styled.div`
  display: flex;
  align-items: center;
  background: #ffffff;
  border-radius: 4px;
  padding: 4px 8px;
  border: 1px solid #d3d3d3;
  font-size: 14px;
  
  > * > * > input {
    all: unset !important;
    width: 100% !important;
  }
  
  > label {
    display: flex;
    width: 34px;
    height: 28px;
    background: url(${Calendar});
    background-size: contain;
    background-position: center;
  }
`;

export const Points = styled.h2`
  width: 100%;
  margin-bottom: 0;
  color: #36b4e6;
`;
