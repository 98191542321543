import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-width: 1250px;
  height: 100vh;
  overflow: auto;
  background: rgb(247, 249, 251);
  padding-bottom: 300px;
`;
export const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 1250px;
  height: 48px;
  background: #36B4E5;
  position: absolute;
  z-index: 2;
  font-weight: bold;
  color: #ffffff;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 1200px;
  width: 95%;
  padding-bottom: 200px;
  align-items: center;
  // padding-top: 40px;
`;

export const Month = styled.div`
  width: 230px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > span {
    margin: 0 20px;
    font-weight: bold;
  }
  margin-top: 40px;
`;

export const PosButton = styled.img`
  width: 36px;
  cursor: pointer;

  ${(props) => props?.name === 'next' && 'transform: rotate(180deg);'}
  ${(props) => props?.disabled && 'filter: opacity(25%); cursor: unset;'}
  
  
  /* 드래그 방지 */
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
`;

export const Content = styled.div`
  display: flex;
  ${(props) => props?.flexDirection && `flex-direction: ${props.flexDirection};`}
  width: 100%;
  box-sizing: border-box;
  background: white;
  border-radius: 8px;
  padding: 16px 20px;
  margin-top: 30px;
  border: 1px solid #e3e3e3;
  
  > h2 {
    margin: 20px 0;
  }
  
  > span {
    :nth-child(1) {
      font-weight: bold;
      margin-right: 30px;
    }
    :nth-child(2) {
      font-weight: bold;
      margin-right: 10px;
    }
    :last-child {
      margin-left: auto;
      font-size: 12px;
      color: gray;
    }
  }
  
`;
