import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import * as style from './styles';
import { PrepaidMenu } from '../../components/PrepaidMenu';
import ChargeModal from '../../components/ChargeModal';
import Spinner from '../../components/Spinner';

export default function UserCharge(props) {
  const navigate = useNavigate();
  const [userData, setUserData] = useState();
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState();
  const [modal, setModal] = useState(false);
  const [hospitalName, setHospitalName] = useState();
  const [price, setPrice] = useState(50000);
  const [count, setCount] = useState({
    sms: 0,
    mms: 0,
    lms: 0,
    calculate: 0,
  });

  const dataURL = `${process.env.REACT_APP_API_URI}api/points`;

  const handleCount = (e) => {
    setCount({ ...count, [e.target.name]: Number(e.target.value.replace(/[^0-9]/g, '')) });
  };

  const handlePoint = (e) => {
    setPrice(Number(e.target.id));
  };

  const handleCalculate = () => {
    const calculated = userData.priceSMS * count.sms
        + userData.priceLMS * count.lms
        + userData.priceMMS * count.mms;
    if (!calculated) {
      alert('발송예정 건수를 입력해주세요.');
      return;
    }
    if (Object.keys(count).map((key) => key !== 'calculate' && Boolean(count[key])).filter((bool) => bool).length >= 2) {
      alert('발송예정 문자타입은 하나만 입력해주세요.');
      return;
    }
    setCount({
      ...count,
      calculate: calculated,
    });
  };

  useEffect(() => {
    setLoading(true);
    const userToken = sessionStorage.getItem('token');
    const name = sessionStorage.getItem('hospitalName');
    if (!userToken) {
      alert('잘못된 접근입니다.');
      navigate('/');
    } else {
      setToken(userToken);
      setHospitalName(name);
      axios.get(dataURL, {
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      }).then((res) => {
        const {
          companyId, priceSMS, priceMMS, priceLMS, email, points,
        } = res.data;
        setUserData({
          companyId, priceSMS, priceMMS, priceLMS, email, points,
        });
        setLoading(false);
      }).catch((err) => {
        const { message, ...userInfo } = err.response.data;
        setUserData(userInfo);
        setLoading(false);
      });
    }
  }, []);
  return (
    <>
      {loading && <Spinner />}
      {modal && (
      <ChargeModal
        companyId={userData?.companyId}
        email={userData?.email}
        setModal={setModal}
        price={price}
        token={token}
        setLoading={setLoading}
      />
      )}
      <style.Header>
        {hospitalName}
      </style.Header>
      <PrepaidMenu />
      <style.MainContainer>
        <style.ContentContainer>
          <h2>잔여포인트 : {(userData?.points || 0).toLocaleString()} P</h2>
          <h3>얼마를 충전해야 할 지 계산해보세요!</h3>
          <style.Content>
            <h3>발송예정 건수</h3>
            <style.SpaceDiv>
              <span>단문(SMS)</span>
              <div>
                <input
                  type="tel"
                  value={count.sms.toLocaleString()}
                  name="sms"
                  onChange={handleCount}
                />건
              </div>
            </style.SpaceDiv>
            <style.SpaceDiv>
              <span>장문(LMS)</span>
              <div>
                <input
                  type="tel"
                  value={count.lms.toLocaleString()}
                  name="lms"
                  onChange={handleCount}
                />건
              </div>
            </style.SpaceDiv>
            <style.SpaceDiv>
              <span>포토(MMS)</span>
              <span className="normal">이미지1장+장문</span>
              <div>
                <input
                  type="tel"
                  value={count.mms.toLocaleString()}
                  name="mms"
                  onChange={handleCount}
                />건
              </div>
            </style.SpaceDiv>
          </style.Content>
          <style.Button background="rgb(74, 76, 96)" onClick={handleCalculate}>
            계산하기
          </style.Button>
          <style.Content>
            <h3>
              충전 필요 금액
              <div>
                <span>부가세 포함</span>
                <h3>{count.calculate - (userData?.points || 0) >= 0
                  ? (count.calculate - (userData?.points || 0)).toLocaleString() : 0} 원
                </h3>
              </div>
            </h3>
            <style.SpaceDiv>
              <span>발송 필요 포인트</span>
              <span>{count.calculate.toLocaleString()} P</span>
            </style.SpaceDiv>
            <style.SpaceDiv>
              <span>잔여포인트 사용</span>
              <span>- {(userData?.points || 0).toLocaleString()} P</span>
            </style.SpaceDiv>
          </style.Content>
          <div />
          <style.Content>
            <h3>문자 포인트 <span>(1원=1P)</span></h3>
            <div>
              <input type="radio" name="price" id="50000" defaultChecked onClick={handlePoint} />
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="50000">50,000원</label>
            </div>
            <div>
              <input type="radio" name="price" id="100000" onClick={handlePoint} />
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="100000">100,000원</label>
            </div>
            <div>
              <input type="radio" name="price" id="200000" onClick={handlePoint} />
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="200000">200,000원</label>
            </div>
            <div>
              <input type="radio" name="price" id="300000" onClick={handlePoint} />
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="300000">300,000원</label>
            </div>
            <div>
              <input type="radio" name="price" id="400000" onClick={handlePoint} />
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="400000">400,000원</label>
            </div>
            <div>
              <input type="radio" name="price" id="500000" onClick={handlePoint} />
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="500000">500,000원</label>
            </div>
            <div>
              <input type="radio" name="price" id="1000000" onClick={handlePoint} />
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="1000000">1,000,000원</label>
            </div>
          </style.Content>
          <style.Button onClick={() => setModal(true)}>
            충전하기
          </style.Button>
        </style.ContentContainer>
      </style.MainContainer>
    </>
  );
}
