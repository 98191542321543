import Select from 'react-select';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import * as style from './styles';
import StyledTable from '../../components/Table';
import UserForm from '../../components/UserForm';
import Spinner from '../../components/Spinner';
import Pages from '../../components/Pages';
import { autoFitColumns, toDateFormatString, formatNumber } from '../../utils';

export default function Admin() {
  const navigate = useNavigate();
  const today = new Date();
  const [currMenu, setCurrMenu] = useState('user');
  const [form, setForm] = useState(false);
  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [formData, setFormData] = useState();
  const [currDate, setCurrDate] = useState({
    year: today.getFullYear(),
    month: today.getMonth() + 1,
  });
  const [currPage, setCurrPage] = useState(1);
  const [isNew, setIsNew] = useState(false);
  const searchOptions = [
    { value: 'id', label: '사업자등록번호' },
    { value: 'phone', label: '발신번호' },
    { value: 'name', label: '병원명' },
  ];
  const [searchQuery, setSearchQuery] = useState({
    type: 'id',
    data: '',
  });
  const [url, setUrl] = useState(`${process.env.REACT_APP_API_URI}api/users?`);

  const yearOption = [];
  const monthOption = [];

  for (let i = 1; i <= 12; i += 1) {
    monthOption.push({ value: i, label: `${i}월` });
  }

  for (let i = new Date().getFullYear(); i >= new Date().getFullYear() - 20; i -= 1) {
    yearOption.push({ value: i, label: `${i}년` });
  }

  const handleMenu = (e) => {
    setCurrMenu(e.target.name);
  };

  const handleUserClick = (e) => {
    if (!token) return;
    setLoading(true);
    axios.get(`${process.env.REACT_APP_API_URI}api/users/${e.target.id}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        setFormData(res.data.user);
        setLoading(false);
        setForm(true);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const handleFormCancel = () => {
    setForm(false);
    setFormData(null);
    setIsNew(false);
  };

  const handleUserSearch = () => {
    if (!token) return;
    setLoading(true);
    setData(null);
    setCurrPage(1);
    setUrl(`${process.env.REACT_APP_API_URI}api/users?${searchQuery.type}=${searchQuery.data}`);
    axios.get(`${process.env.REACT_APP_API_URI}api/users?${searchQuery.type}=${searchQuery.data}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      setData(res.data);
      setLoading(false);
    }).catch((err) => {
      if (err.response?.status === 403) {
        alert('잘못된 접근입니다.');
        navigate('/');
      } else {
        if (err.response?.status === 404) {
          alert('검색조건을 확인 후 다시 검색하세요.');
        }
        console.log(err);
        setLoading(false);
      }
    });
  };

  const handleSettlementSearch = () => {
    if (!token) return;
    setLoading(true);
    setData(null);
    setCurrPage(1);
    setUrl(`${process.env.REACT_APP_API_URI}api/messages/calculate?year=${currDate.year}&month=${currDate.month}&${searchQuery.type}=${searchQuery.data}`);
    axios.get(`${process.env.REACT_APP_API_URI}api/messages/calculate?year=${currDate.year}&month=${currDate.month}&${searchQuery.type}=${searchQuery.data}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      setData(res.data);
      setLoading(false);
    }).catch((err) => {
      if (err.response?.status === 403) {
        alert('잘못된 접근입니다.');
        navigate('/');
      } else {
        if (err.response?.status === 404) {
          alert('검색조건을 확인 후 다시 검색하세요.');
        }
        console.log(err);
        setLoading(false);
      }
    });
  };

  const getUsers = () => {
    if (!token) return;
    setLoading(true);
    if (currMenu === 'user') {
      axios.get(`${url.includes('=') ? `${url}&` : url}page=${currPage}`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }).then((res) => {
        setData(res.data);
        setLoading(false);
      }).catch((err) => {
        console.log(err);
        setLoading(false);
      });
    }
  };

  const getSettlement = () => {
    if (!token) return;
    if (currDate.year === today.getFullYear() && currDate.month > today.getMonth() + 1) {
      setData(null);
      return;
    }
    setLoading(true);
    setUrl(`${process.env.REACT_APP_API_URI}api/messages/calculate?year=${currDate.year}&month=${currDate.month}`);
    axios.get(`${process.env.REACT_APP_API_URI}api/messages/calculate?year=${currDate.year}&month=${currDate.month}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      setData(res.data);
      setLoading(false);
    }).catch((err) => {
      console.log(err);
      setLoading(false);
    });
  };

  const handleExcelDownload = async () => {
    const excelFileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const excelFileExtension = '.xlsx';
    const excelFileName = `${currDate.year}년 ${currDate.month}월 정산내역`;

    if (!token) return;
    setLoading(true);
    const res = await axios.get(`${process.env.REACT_APP_API_URI}api/messages/calculate/all?year=${currDate.year}&month=${currDate.month}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });

    const ws = XLSX.utils.aoa_to_sheet([
      ['no.', '병원명', '사업자등록번호', '상호', '대표자명', '주소', '업종', '업태', '세금계산서 email', '발신번호', 'SMS(건)', 'LMS(건)', 'MMS(건)', '청구금액(원)'],
    ]);
    res.data.messageCounts.map((calculated, index) => {
      XLSX.utils.sheet_add_aoa(
        ws,
        [
          [
            index + 1,
            calculated.user.hospitalName,
            calculated.user.companyId,
            calculated.user.companyName,
            calculated.user.representName,
            calculated.user.address,
            calculated.user.companyType,
            calculated.user.companyItem,
            calculated.user.email,
            calculated.user.outgoingNumber,
            calculated.sms,
            calculated.lms,
            calculated.mms,
            formatNumber(
              ((calculated?.sms && calculated?.user?.priceSMS
                && calculated.sms * calculated.user.priceSMS) || 0)
              + ((calculated?.lms && calculated?.user?.priceLMS
                && calculated.lms * calculated.user.priceLMS) || 0)
              + ((calculated?.mms && calculated?.user?.priceMMS
                && calculated.mms * calculated.user.priceMMS) || 0),
            ),
          ],
        ],
        { origin: -1 },
      );
      return false;
    });
    autoFitColumns(ws);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelButter = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const excelFile = new Blob([excelButter], { type: excelFileType });
    FileSaver.saveAs(excelFile, excelFileName + excelFileExtension);
    setLoading(false);
  };

  useEffect(() => {
    const menuURL = window.location.search && window.location.search.split('=')[1] === 'settlement'
      ? `${process.env.REACT_APP_API_URI}api/messages/calculate?year=${today.getFullYear()}&month=${today.getMonth() + 1}`
      : `${process.env.REACT_APP_API_URI}api/users`;
    if (window.location.search) setCurrMenu(window.location.search.split('=')[1]);
    setLoading(true);
    const userToken = sessionStorage.getItem('token');
    if (!userToken) {
      alert('잘못된 접근입니다.');
      navigate('/');
    } else {
      setToken(userToken);
      axios.get(menuURL, {
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      }).then((res) => {
        setData(res.data);
        setLoading(false);
      }).catch((err) => {
        if (err.response?.status === 403) {
          alert('잘못된 접근입니다.');
          navigate('/');
        } else {
          console.log(err);
          setLoading(false);
        }
      });
    }
  }, []);

  useEffect(() => {
    setCurrPage(1);
    setSearchQuery({
      type: 'id',
      data: '',
    });
    setLoading(true);
    setData(null);
    if (currMenu === 'user') {
      if (!token) return;
      setUrl(`${process.env.REACT_APP_API_URI}api/users?`);
      axios.get(`${process.env.REACT_APP_API_URI}api/users?`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }).then((res) => {
        setData(res.data);
        setLoading(false);
      }).catch((err) => {
        console.log(err);
        setLoading(false);
      });
    } else if (currMenu === 'settlement') {
      if (!token) return;
      setCurrDate({
        year: today.getFullYear(),
        month: today.getMonth() + 1,
      });
      setUrl(`${process.env.REACT_APP_API_URI}api/messages/calculate?year=${today.getFullYear()}&month=${today.getMonth() + 1}`);
      axios.get(`${process.env.REACT_APP_API_URI}api/messages/calculate?year=${today.getFullYear()}&month=${today.getMonth() + 1}`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }).then((res) => {
        setData(res.data);
        setLoading(false);
      }).catch((err) => {
        console.log(err);
        setLoading(false);
      });
    }
  }, [currMenu]);

  useEffect(() => {
    if (currMenu === 'user') getUsers();
    else if (currMenu === 'settlement') getSettlement();
  }, [currPage, currDate]);

  useEffect(() => {
    setSearchQuery({ ...searchQuery, data: '' });
    setCurrPage(1);
  }, [currDate]);

  return (
    <>
      { loading && <Spinner /> }
      <style.Header>
        <button type="button" name="user" onClick={handleMenu}>고객 목록</button>
        <button type="button" name="admin-charge" onClick={() => navigate('/admin-charge?menu=accept-list')}>포인트 신청</button>
        <button type="button" name="admin-charge" onClick={() => navigate('/admin-charge?menu=point-list')}>포인트 지급</button>
        <button type="button" name="settlement" onClick={handleMenu}>정산 목록</button>
      </style.Header>
      <style.MainContainer>

        <style.ContentContainer>
          {currMenu === 'user' && (
          <style.Content flexDirection="column">
            <h2>문자발송기 고객 목록</h2>
            <style.Search>
              <Select
                options={searchOptions}
                defaultValue={searchOptions[0]}
                onChange={(newValue) => {
                  setSearchQuery({ ...searchQuery, type: newValue.value });
                }}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    width: '180px',
                  }),
                }}
              />
              <input
                type="text"
                onChange={(e) => {
                  setSearchQuery({ ...searchQuery, data: e.target.value });
                }}
              />
              <button type="button" onClick={handleUserSearch}>Search</button>
            </style.Search>
            <button
              type="button"
              onClick={() => {
                setForm(true);
                setIsNew(true);
              }}
            >등록
            </button>
            <StyledTable longTable hover>
              <thead>
                <tr>
                  <th>no.</th>
                  <th>등록일</th>
                  <th>병원명</th>
                  <th>대표자명</th>
                  <th>주소</th>
                  <th>사업자등록번호</th>
                  <th>잔여포인트</th>
                  <th>발신번호</th>
                  <th>SMS</th>
                  <th>LMS</th>
                  <th>MMS</th>
                </tr>
              </thead>
              <tbody>
                {
                !loading && data && data?.users && data.users.map((user, index) => (
                  <tr key={user._id} id={user._id} onClick={handleUserClick}>
                    <td id={user._id}>{(currPage - 1) * 15 + index + 1}</td>
                    <td id={user._id}>{toDateFormatString(new Date(user.createdAt))}</td>
                    <td id={user._id}>{user.hospitalName}</td>
                    <td id={user._id}>{user.representName}</td>
                    <td id={user._id}>{user.address}</td>
                    <td id={user._id}>{user.companyId}</td>
                    <td id={user._id}>{user.role === 2 ? user.points.toLocaleString() : '-'}</td>
                    <td id={user._id}>{user.outgoingNumber}</td>
                    <td id={user._id}>{user.priceSMS}</td>
                    <td id={user._id}>{user.priceLMS}</td>
                    <td id={user._id}>{user.priceMMS}</td>
                  </tr>
                ))
              }
              </tbody>
            </StyledTable>
            {data
              && <Pages totalPage={data.totalPage} currPage={currPage} setCurrPage={setCurrPage} />}
          </style.Content>
          )}
          { currMenu === 'settlement' && (
            <style.Content flexDirection="column">
              <h2>고객 정산목록</h2>
              <style.Search>
                <Select
                  options={yearOption}
                  defaultValue={yearOption[0]}
                  onChange={(newValue) => {
                    setCurrDate({ ...currDate, year: newValue.value });
                    setCurrPage(1);
                  }}
                />
                <Select
                  options={monthOption}
                  defaultValue={monthOption[today.getMonth()]}
                  onChange={(newValue) => {
                    setCurrDate({ ...currDate, month: newValue.value });
                    setCurrPage(1);
                  }}
                />
                <Select
                  options={searchOptions}
                  defaultValue={searchOptions[0]}
                  onChange={(newValue) => {
                    setSearchQuery({ ...searchQuery, type: newValue.value });
                  }}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      width: '180px',
                    }),
                  }}
                />
                <input
                  type="text"
                  onChange={(e) => {
                    setSearchQuery({ ...searchQuery, data: e.target.value });
                  }}
                  value={searchQuery.data}
                />
                <button type="button" onClick={handleSettlementSearch}>Search</button>
              </style.Search>
              <button type="button" onClick={handleExcelDownload}>다운로드</button>
              <StyledTable>
                <thead>
                  <tr>
                    <th>no.</th>
                    <th>병원명</th>
                    <th>대표자명</th>
                    <th>주소</th>
                    <th>사업자등록번호(ID)</th>
                    <th>발신번호</th>
                    <th>청구금액</th>
                  </tr>
                </thead>
                <tbody>
                  {/* eslint-disable-next-line max-len */}
                  { !loading && data && data?.messageCounts && data.messageCounts.map((calculated, index) => (
                    <tr key={calculated.user._id}>
                      <td>{(currPage - 1) * 15 + index + 1}</td>
                      <td>{calculated.user.hospitalName}</td>
                      <td>{calculated.user.representName}</td>
                      <td>{calculated.user.address}</td>
                      <td>{calculated.user.companyId}</td>
                      <td>{calculated.user.outgoingNumber}</td>
                      <td>
                        {formatNumber(
                          ((calculated?.sms && calculated?.user?.priceSMS
                            && calculated.sms * calculated.user.priceSMS) || 0)
                        + ((calculated?.lms && calculated?.user?.priceLMS
                            && calculated.lms * calculated.user.priceLMS) || 0)
                        + ((calculated?.mms && calculated?.user?.priceMMS
                          && calculated.mms * calculated.user.priceMMS) || 0),
                        )}원
                      </td>
                    </tr>
                  ))}
                </tbody>
              </StyledTable>
              {data
                && (
                <Pages
                  totalPage={data.totalPage}
                  currPage={currPage}
                  setCurrPage={setCurrPage}
                />
                )}
            </style.Content>
          ) }
          { form
            && (
            <UserForm
              isNew={isNew}
              cancel={handleFormCancel}
              formData={formData}
              setLoading={setLoading}
              token={token}
            />
            ) }
        </style.ContentContainer>
      </style.MainContainer>
    </>
  );
}
