import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import * as style from './styles';
import logo from '../../assets/logo.png';
import Spinner from '../../components/Spinner';

export default function Login() {
  const navigate = useNavigate();
  const [loginData, setLoginData] = useState({
    companyId: '',
    password: '',
  });
  const [loading, setLoading] = useState(false);
  const handleLogin = (e) => {
    if (Object.values(loginData).map((data) => data || false).includes(false)) {
      alert('로그인 정보를 모두 입력해주세요.');
    } else {
      setLoading(true);
      axios.post(`${process.env.REACT_APP_API_URI}api/token`, loginData)
        .then((res) => {
          sessionStorage.setItem('token', res.data.token);
          sessionStorage.setItem('hospitalName', res.data.companyName);
          navigate(res.data.url);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          if (err?.response?.status === 401) alert('로그인 정보가 올바르지 않습니다. 아이디와 비밀번호를 확인하세요.');
          else alert('잠시 후 다시 시도해주세요.');
          setLoading(false);
        });
    }
  };
  const handleInput = (e) => {
    if (e.key === 'Enter') handleLogin();
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
  };
  return (
    <style.MainContainer>
      {loading && <Spinner />}
      <style.Login>
        <img src={logo} alt="문자연구소" />
        <span>문자연구소 회원의 단건 및 단체문자 발송 서비스입니다.</span>
        <input type="text" name="companyId" placeholder="아이디(숫자만 10자리)" maxLength="10" onChange={handleInput} onKeyDown={handleInput} />
        <input type="password" name="password" placeholder="비밀번호" maxLength="12" onChange={handleInput} onKeyDown={handleInput} />
        <button type="button" onClick={handleLogin}>로그인</button>
      </style.Login>
    </style.MainContainer>
  );
}
