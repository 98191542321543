const fetchCSV = () => fetch('/error-code.csv').then((res) => {
  const reader = res.body.getReader();
  const decoder = new TextDecoder('utf-8');
  return reader.read().then((result) => decoder.decode(result.value));
});

export async function getErrorList() {
  const csvFile = await fetchCSV();
  const rows = csvFile.split('\r\n');
  const obj = {};
  for (let i = 1; i < rows.length; i += 1) {
    const data = rows[i].split(',');
    // eslint-disable-next-line prefer-destructuring
    obj[data[0]] = data[1];
  }
  return obj;
}

export const toDateFormatString = (date) => { // Date 변환
  date.setHours(date.getHours() + 9);
  return date.toISOString()
    .replace('T', ' ')
    .substring(0, 19);
};

export const pagination = (totalPage, currPage) => { // Pagination
  const validNext = Math.ceil(totalPage / 5);
  const ceilCurrPage = Math.ceil(currPage / 5);
  const pages = [];
  const start = currPage % 5 === 0 ? currPage - 4 : Math.floor(currPage / 5) * 5 + 1;
  const end = ceilCurrPage < validNext ? ceilCurrPage * 5 : totalPage;
  for (let i = start; i <= end; i += 1) pages.push(i);
  return {
    pages,
    prev: Math.floor((currPage - 1) / 5) > 0,
    next: end !== totalPage,
  };
};

export function autoFitColumns(worksheet) {
  if (!worksheet) return;
  // eslint-disable-next-line no-unsafe-optional-chaining
  const [firstCol, lastCol] = worksheet['!ref']?.replace(/d/, '')
    .split(':');

  const numRegexp = /\d+$/g;

  const firstColIndex = firstCol.charCodeAt(0);
  const lastColIndex = lastCol.charCodeAt(0);
  const rows = +numRegexp.exec(lastCol)[0];

  const objectMaxLength = [];

  // Loop on columns
  for (let colIndex = firstColIndex; colIndex <= lastColIndex; colIndex += 1) {
    const col = String.fromCharCode(colIndex);
    let maxCellLength = 0;

    // Loop on rows
    for (let row = 1; row <= rows; row += 1) {
      const cellLength = worksheet[`${col}${row}`].v.length + 1;
      if (cellLength > maxCellLength) maxCellLength = cellLength;
    }

    objectMaxLength.push({ width: maxCellLength });
  }
  // eslint-disable-next-line no-param-reassign
  worksheet['!cols'] = objectMaxLength;
}

export function formatNumber(number) {
  const parts = number.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
}

// eslint-disable-next-line consistent-return
export function doCopy(text) {
  if (navigator.clipboard) {
    // (IE는 사용 못하고, 크롬은 66버전 이상일때 사용 가능합니다.)
    navigator.clipboard
      .writeText(text)
      .then(() => {
        alert('클립보드에 복사되었습니다.');
      })
      .catch(() => {
        alert('복사를 다시 시도해주세요.');
      });
  } else {
    if (!document.queryCommandSupported('copy')) {
      return alert('복사하기가 지원되지 않는 브라우저입니다.');
    }
    const textarea = document.createElement('textarea');
    textarea.value = text;
    textarea.style.top = '0';
    textarea.style.left = '0';
    textarea.style.position = 'fixed';
    document.body.appendChild(textarea);
    textarea.focus();
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    alert('클립보드에 복사되었습니다.');
  }
}
