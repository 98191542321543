import styled from 'styled-components';

export const SpinnerContainer = styled.div`
  width: 100%;
  min-width: 1250px;
  height: 100vh;
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  background: rgb(255, 255, 255, 0.8);
  z-index: 11;
`;

export const SpinnerImage = styled.img`
  width: 100px;
  
  /* 드래그 방지 */
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
`;
