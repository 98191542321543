import { useEffect, useState } from 'react';
import { pagination } from '../../utils/index';
import skip from '../../assets/month_move.svg';
import * as style from './styles';

export default function Pages(props) {
  const { totalPage = 1, currPage = 1, setCurrPage } = props;
  const [pageData, setPageData] = useState();
  const handlePage = (e) => {
    setCurrPage(Number(e.target.id));
  };
  const handleSkip = (e) => {
    if (pageData[e.target.name]) {
      setCurrPage(e.target.name === 'next'
        ? Math.ceil(currPage / 5) * 5 + 1
        : Math.floor(currPage / 5) * 5);
    }
  };
  useEffect(() => {
    setPageData(pagination(totalPage, currPage));
  }, [props]);

  return (
    <style.Pages>
      { pageData && <style.Skip src={skip} name="prev" disabled={!pageData.prev} onClick={handleSkip} /> }
      { pageData
        && pageData.pages.map((page) => (
          <style.Page
            active={page === currPage}
            key={`${page}-page-button`}
            id={page}
            onClick={handlePage}
          >
            {page}
          </style.Page>
        )) }
      { pageData && <style.Skip src={skip} name="next" disabled={!pageData.next} onClick={handleSkip} /> }
    </style.Pages>
  );
}
