import React, { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './pages/Login';
import User from './pages/User';
import Admin from './pages/Admin';
import UserCharge from './pages/UserCharge';
import UserChargeList from './pages/UserChargeList';
import AdminCharge from './pages/AdminCharge';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route exact path="/user" element={<User />} />
        <Route exact path="/admin" element={<Admin />} />
        <Route exact path="/user-charge" element={<UserCharge />} />
        <Route exact path="/user-charge-list" element={<UserChargeList />} />
        <Route exact path="/admin-charge" element={<AdminCharge />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
