import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import * as style from './styles';

export function PrepaidMenu(props) {
  const location = useLocation();
  const [active, setActive] = useState('');
  useEffect(() => {
    setActive(location.pathname.split('/').pop());
  }, []);

  return (
    <style.Container>
      <style.Link href="/user" active={active === 'user'}>발송내역</style.Link>
      <style.Link href="/user-charge" active={active === 'user-charge'}>충전하기</style.Link>
      <style.Link href="/user-charge-list" active={active === 'user-charge-list'}>충전내역</style.Link>
    </style.Container>
  );
}
