import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  min-width: 1250px;
  height: 48px;
  background: #36B4E5;
  font-size: 16px;
  color: white;
  font-weight: bold;
  padding: 0px 20px;
  box-sizing: border-box;
  > button {
    all: unset;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    margin: 0px 10px
  }
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-width: 1250px;
  height: 100vh;
  overflow: auto;
  background: rgb(247, 249, 251);
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 1200px;
  width: 95%;
  padding-bottom: 100px;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  ${(props) => props?.flexDirection && `flex-direction: ${props.flexDirection};`}
  width: 100%;
  box-sizing: border-box;
  background: white;
  border-radius: 8px;
  padding: 16px 20px;
  margin-top: 30px;
  border: 1px solid #e3e3e3;
  > h2 {
    margin: 20px 0;
  }
  
  > button {
    all: unset;
    display: flex;
    align-self: flex-start;
    margin-bottom: 10px;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border-radius: 4px;
    width: 80px;
    height: 40px;
    background: #36B4E5;
    color: white;
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
  }
`;

export const Button = styled.button`
  all: unset;
  cursor: pointer;
  border: 1px solid ${(props) => (props.active ? '#36B4E5' : '#ef0000')};
  border-radius: 4px;
  padding: 4px;
  color: ${(props) => (props.active ? '#36B4E5' : '#ef0000')};
  font-weight: bold;
`;

export const Search = styled.div`
  align-self: self-end;
  display: flex;
  gap:10px;
  height: 40px;
  box-sizing: border-box;

  > button {
    all: unset;
    display: flex;
    align-self: flex-start;
    margin-bottom: 10px;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border-radius: 4px;
    width: 80px;
    height: 40px;
    border: 1px solid rgb(94, 178, 221);
    color: rgb(94, 178, 221);
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
  }

  > input {
    all: unset;
    width: 260px;
    height: 40px;
    border: 1px solid lightgray;
    border-radius: 4px;
    padding: 4px 8px;
    font-size: 14px;
    box-sizing: border-box;
  }
`;
