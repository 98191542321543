import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import * as style from './styles';
import { toDateFormatString } from '../../utils';

export default function UserForm(props) {
  const {
    cancel, formData, setLoading, token, isNew,
  } = props;
  const [data, setData] = useState({});
  const [point, setPoint] = useState({
    add: 0,
    del: 0,
  });

  const handleOnChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleOnSave = (e) => {
    if (!token) return;
    setLoading(true);
    if (isNew) {
      if (!data?.password) {
        setLoading(false);
        alert('비밀번호 초기화를 진행해주세요.');
        return;
      }
      axios.post(`${process.env.REACT_APP_API_URI}api/users`, data, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }).then((res) => {
        alert('회원 등록이 완료되었습니다.');
        setLoading(false);
        window.location.replace('/admin');
      }).catch((err) => {
        setLoading(false);
        if (err?.response?.status === 400) {
          alert('모든 정보를 입력해주세요.');
          return;
        }
        alert('회원 등록을 실패하였습니다. 다시 시도해주세요.');
      });
    } else {
      axios.post(`${process.env.REACT_APP_API_URI}api/users/${formData._id}`, data, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }).then((res) => {
        alert('정보 변경이 완료되었습니다.');
        setLoading(false);
        window.location.replace('/admin');
      }).catch((err) => {
        alert('정보 변경에 실패하였습니다. 다시 시도해주세요.');
        setLoading(false);
      });
    }
  };

  const handleCancel = (e) => {
    if (Object.keys(data).length && !window.confirm('변경 사항을 저장하지 않으셨습니다. 창을 닫겠습니까?')) return;
    cancel();
  };

  const handleResetPassword = (e) => {
    if (window.confirm('비밀번호를 초기화합니다. 사업자등록번호(ID)로 초기화됩니다. 예)0000000000')) {
      if (isNew && !data.companyId) {
        alert('사업자등록번호를 먼저 입력해주세요.');
      } else setData({ ...data, password: (data.companyId || formData.companyId) });
    }
  };

  const handleDeleteUser = (e) => {
    if (window.confirm('사용자를 삭제하겠습니까? 제거 시, 정보를 복구할 수 없습니다.')) {
      setLoading(true);
      axios.delete(`${process.env.REACT_APP_API_URI}api/users/${formData._id}`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }).then((res) => {
        alert('삭제가 완료되었습니다.');
        setLoading(false);
        window.location.replace('/admin');
      }).catch((err) => {
        alert('삭제에 실패하였습니다. 다시 시도해주세요.');
        setLoading(false);
      });
    }
  };

  const handlePoint = (e) => {
    setPoint({
      ...point,
      [e.target.name.split('point-')[1]]: Number(e.target.value.replace(/[^0-9]/g, '')),
    });
  };

  const submitPoint = (e) => {
    const price = e.target.name === 'add' ? point.add : -point.del;
    if (price === 0) alert('포인트를 입력해주세요.'); else if (price < 0) {
      if (!window.confirm(`${(-price).toLocaleString()} 포인트를 삭제합니다.`)) return;
    } else if (!window.confirm(`${price.toLocaleString()} 포인트를 충전합니다.`)) return;
    setLoading(true);
    axios.post(`${process.env.REACT_APP_API_URI}api/points/`, {
      userId: formData._id,
      name: '문자발송기',
      price,
    }, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      alert('포인트 설정이 완료되었습니다.');
      setLoading(false);
    }).catch((err) => {
      alert('포인트 설정에 실패하였습니다. 다시 시도해주세요.');
      setLoading(false);
    });
  };

  return (
    <style.FormContainer>
      <style.Form>
        <h2>고객 등록</h2>
        <style.Input>
          <span>병원명</span>
          <input type="text" name="hospitalName" placeholder="입력" maxLength="20" defaultValue={formData?.hospitalName || ''} onChange={handleOnChange} />
        </style.Input>
        <style.SideFormWrapper>
          <div>
            <style.Input>
              <span>사업자등록번호 = ID</span>
              <style.Input row>
                <input type="text" name="companyId" placeholder="0000000000" maxLength="10" style={{ width: '100%', marginRight: '10px' }} defaultValue={formData?.companyId || ''} onChange={handleOnChange} />
                <button type="button" style={{ width: '120px', flexShrink: '0' }} onClick={handleResetPassword}>비밀번호 초기화</button>
              </style.Input>
            </style.Input>
            <style.Input>
              <span>상호(사업자등록증)</span>
              <input type="text" name="companyName" placeholder="사업자등록증 표기 상호" maxLength="20" defaultValue={formData?.companyName || ''} onChange={handleOnChange} />
            </style.Input>
            <style.Input>
              <span>대표자명</span>
              <input type="text" name="representName" placeholder="입력" maxLength="10" defaultValue={formData?.representName || ''} onChange={handleOnChange} />
            </style.Input>
            <style.Input>
              <span>세금계산서 담당자 연락처</span>
              <style.Input row>
                <input type="text" name="managerName" style={{ width: '120px' }} placeholder="담당자 이름" maxLength="10" defaultValue={formData?.managerName || ''} onChange={handleOnChange} />
                <input type="text" name="managerPhone" style={{ width: '100%', marginLeft: '10px' }} placeholder="숫자만 8~11자" maxLength="11" defaultValue={formData?.managerPhone || ''} onChange={handleOnChange} />
              </style.Input>
            </style.Input>
            <style.Input>
              <span>세금계산서 email</span>
              <input type="email" name="email" placeholder="입력" maxLength="30" defaultValue={formData?.email || ''} onChange={handleOnChange} />
            </style.Input>
            <style.Input row>
              <div style={{ width: '50%' }}>
                <input type="radio" name="role" value="2" id="prepaid" defaultChecked={formData?.role === 2} onChange={handleOnChange} />
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="prepaid">선불제</label>
              </div>
              <div style={{ width: '50%' }}>
                <input type="radio" name="role" value="1" id="normal" defaultChecked={formData?.role === 1} onChange={handleOnChange} />
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="normal">후불제</label>
              </div>
            </style.Input>
            {formData?.role === 2 && (
            <style.Input>
              <span>문자 포인트</span>
              <style.Input row>
                <input type="tel" name="point-add" style={{ width: '100%' }} placeholder="숫자로 입력" onChange={handlePoint} value={point.add.toLocaleString()} />
                <button type="button" style={{ width: '240px', marginLeft: '10px' }} onClick={submitPoint} name="add">수동 충전</button>
              </style.Input>
              <style.Input row style={{ marginTop: '8px' }}>
                <input type="tel" name="point-del" style={{ width: '100%' }} placeholder="숫자로 입력" onChange={handlePoint} value={point.del.toLocaleString()} />
                <button type="button" style={{ width: '240px', marginLeft: '10px', background: '#ef0000' }} onClick={submitPoint} name="del">수동 삭제</button>
              </style.Input>
            </style.Input>
            )}
            {!isNew && (
            <style.Input>
              <span>사용자 삭제하기</span>
              <button type="button" style={{ width: '100%', background: '#ef0000' }} onClick={handleDeleteUser}>사용자 제거</button>
            </style.Input>
            )}
          </div>
          <div>
            <style.Input>
              <span>업태/종목</span>
              <style.Input row>
                <input type="text" name="companyType" placeholder="업태" maxLength="20" defaultValue={formData?.companyType || ''} onChange={handleOnChange} />
                <input type="text" name="companyItem" placeholder="종목" maxLength="20" defaultValue={formData?.companyItem || ''} onChange={handleOnChange} />
              </style.Input>
            </style.Input>
            <style.Input>
              <span>발신번호</span>
              <input type="text" name="outgoingNumber" placeholder="숫자만 8~11자" maxLength="11" defaultValue={formData?.outgoingNumber || ''} onChange={handleOnChange} />
            </style.Input>
            <style.Input>
              <span>주소</span>
              <input type="text" name="address" placeholder="입력" maxLength="80" defaultValue={formData?.address || ''} onChange={handleOnChange} />
            </style.Input>
            <style.Input>
              <span>SMS</span>
              <input type="number" name="priceSMS" step="0.1" min="0" placeholder="숫자로 입력" defaultValue={formData?.priceSMS || ''} onChange={handleOnChange} />
            </style.Input>
            <style.Input>
              <span>LMS</span>
              <input type="number" name="priceLMS" step="0.1" min="0" placeholder="숫자로 입력" defaultValue={formData?.priceLMS || ''} onChange={handleOnChange} />
            </style.Input>
            <style.Input>
              <span>MMS</span>
              <input type="number" name="priceMMS" step="0.1" min="0" placeholder="숫자로 입력" defaultValue={formData?.priceMMS || ''} onChange={handleOnChange} />
            </style.Input>
          </div>
        </style.SideFormWrapper>
        <style.Input row style={{ marginTop: '15px' }}>
          <button type="button" onClick={handleCancel}>취소</button>
          <button type="button" onClick={handleOnSave}>저장</button>
        </style.Input>
      </style.Form>
    </style.FormContainer>
  );
}
